module.exports = {
    pcregister: {
        p1: 'Existing account?',
        dl: 'Sign in',
        zc: 'register',
        p4: 'account',
        p5: 'Please enter the account number',
        p6: 'Please input a password',
        p7: 'Please enter the confirmation password',
        p8: 'Please enter the invitation code',
        p9: 'I agree',
        p10: 'User Agreement and Privacy Policy',
        p11: 'mailbox',
        p12: 'Please enter your email address',
        p13: 'Please enter the email verification code',
        p14: 'send out',
        p15: 'year',
        p16: 'month',
        p17: 'day',
        p18: 'hour',
        p19: 'minutes',
        p20: 'seconds'
    },
    pclogin: {
        l1: 'No account?',
        l2: 'register',
        l3: 'Sign in',
        l4: 'enter one user name',
        l5: 'Please input a password',
        l6: 'Forgot password',
        l7: 'Sign in'
    },
    pccontract: {
        ss: 'market price',
        xj: 'fixed price',
        gg: 'lever'
    },
    pcrapidly: {
        zf: 'Increase',
        dd: 'order',
        bdsj: 'Local time',
        ky: 'available'
    },
    pchome: {
        global1: 'The world leading digital asset trading platform',
        global2: 'It provides secure and reliable digital asset trading and asset management services for millions of users in more than 100 countries.',
        global3: 'Safe and reliable',
        global4: 'Five years of experience in digital asset financial services. Focus on distributed architecture and anti DDoS attack system.',
        global5: 'Global ecological layout',
        global6: 'Localized trading service centers have been set up in many countries to create a blockchain ecosystem integrating multiple formats.',
        global7: 'User First',
        global8: 'Establish an advance compensation mechanism and an investor protection fund.',
        whever1: 'Multi platform terminal transaction anytime, anywhere',
        whever2: 'It covers multiple platforms including iOS, Android and Windows, and supports full business functions.',
        register1: 'Register a',
        register3: 'Global account starts trading',
        register2: 'Register Now'
    },
    pcbottom: {
        box1: 'Global leader',
        box2: 'One of the best teams',
        box3: 'more',
        box4: 'About us',
        box5: 'white paper',
        box6: 'service',
        box7: 'download',
        box8: 'common problem',
        box9: 'Online Service',
        box10: 'regulations',
        box11: 'User Agreement',
        box12: 'Privacy Policy',
        box13: 'Legal statement',
        box14: 'contact'
    },
    pcAbout_us: {
        t1: 'Course',
        t2: '7 years on the road',
        t3: 'Since its birth in 2015, we has devoted itself to blockchain services and dedicated technology and channel construction services.',
        t4: 'From 2014 to 2017, it experienced an upward period of steady and increasing,2017-2018 first half of the buy money to get rich crazy period,From the second half of 2019 to the cold winter period today, I have developed a real skill.Countless successful partners,so that low-key we have a high-profile reputation in the industry.In 2022, we will have a brand new platform V3.0 and set up a new joint-stock company BitGoldEX, to conduct business in more than 100 countries around the world.',
        t5: 'Who are we looking for',
        t6: 'From its inception to today',
        t7: 'I have been looking for the service providers who have difficulties and have the same taste with me.I have successfully solved the problems for dozens of service providers over several years,',
        t8: 'In todays rational world, if youre looking for a good partner, someone who can move forward with you -- perhaps, talk to us.',
        t9: 'Be assured that the person youre looking for has been looking for you.',
        t10: 'Service',
        t11: 'Contact',
        t12: 'When a friend introduces you to a business, you are a good person. When I introduce you to business, I think you have a good reputation.When a client introduces you to a business, it proves that you are trustworthy.',
        t13: 'Spirit',
        t14: 'We will be there where we are needed',
        t15: 'Different customers have different requirements. Different products have different characteristics. The goal is different at different times.The meaning of our existence is to adapt to the concept, system and requirements of different service providers, to solve a variety of problems.',
        t16: 'We would like to be a brick with ideas and functions，We will be there where we are needed.'
    },
    pcproblem: {
        p1: 'Questions',
        p2: 'Why does the converted amount of assets change? ',
        p3: 'The equivalent calculation in the asset is the value of the current holding digital currency converted into USDT, which changes due to the price fluctuation of the digital asset. The number of your digital assets has not changed.',
        p4: 'Forgot the funds password processing method for platform transactions ',
        p5: 'If you forget the trading fund password on the platform, please go to "My-Settings-Click Set Fund Password" or contact customer service to reset',
        p6: 'Where is the capital flow after transaction purchase?  ',
        p7: 'After the purchase order is completed, the funds will enter the French currency account in the asset. If it is necessary to carry out contract trading or futures trading for purchased assets, please go to the fund transfer module for transfer.',
        p8: 'Why limit the number of changes to nicknames ',
        p9: 'For the security reasons of your transaction, we currently limit the nickname to be modified only once.',
        p10: 'What do you mean when you transfer? Why do you need to transfer ',
        p11: 'In order to ensure the independence of funds between your accounts and facilitate your reasonable risk control, we hereby divide the accounts of each major transaction module. Transfer refers to the process of assets conversion between trading accounts.',
        p12: 'How to calculate the account equity of the user contract? ',
        p13: 'Contract account equity = position margin + position floating profit and loss + current account available amount',
        p14: 'What is the value of the stop loss and profit setting in opening a position? How should it be set?  ',
        p15: 'Take profit and stop loss as the upper limit of profit and loss set by you. When the set amount of stop profit and stop loss is reached, the system will automatically close the position. It can be used for risk control when you buy a contract. Half of the set profit-taking amount is: amount of increase x quantity x leverage multiple, set stop loss. We recommend that you set it according to your actual asset situation and reasonably control the risk.',
        p16: 'How to reduce contract risk?  ',
        p17: 'You can transfer the available assets of the remaining accounts to the contract account by transferring funds, or reduce the risk by reducing the open positions.',
        p18: 'What does the quantity in the contract opening mean?  ',
        p19: 'The quantity in the open position represents the number of currencies you expect to buy. For example: select on the opening page of the BTC/USDT trading pair, buy long, enter the price as 1000USDT, and enter the amount as 10BTC, it means: you expect to buy 10 BTC with a unit price of 1000USDT.',
        p20: 'How is the handling fee in the contract calculated?  ',
        p21: 'Handling fee=opening price*opening quantity*handling fee rate',
        p22: 'Notes on forced liquidation ',
        p23: 'The degree of risk is an indicator to measure the risk of your assets. When the degree of risk is equal to 100%, your position is regarded as a liquidation, the degree of risk = (holding margin/contract account equity)*100%, in order to prevent users from wearing Position, the system sets the adjustment ratio of risk degree. When the risk degree reaches the risk value set by the system, the system will force the position to close. For example: the adjustment ratio set by the system is 10%, then when your risk degree is greater than or equal to 90%, all your positions will be forced to be closed by the system.',
        p24: 'What are the contract trading rules? ',
        p25: 'Transaction type',
        p26: 'Trading types are divided into two directions: long positions (buy) and short positions (sell):',
        p27: 'Buy long (bullish) means that you think that the current index is likely to rise, and you want to buy a certain number of certain contracts at the price you set or the current market price.',
        p28: 'Sell short (bearish) means that you think that the current index is likely to fall, and you want to sell a certain number of new contracts at a price you set or the current market price.',
        p29: 'Ordering method',
        p30: 'Limited price order: you need to specify the price and quantity of the order placed',
        p31: 'Market order: you only need to set the order quantity, the price is the current market price',
        p32: 'Positions',
        p33: 'When the order you submit for opening a position is completed, it is called a position',
        p34: 'Contract delivery issues',
        p35: 'The platform contract is a perpetual contract with no set delivery time. As long as the system does not meet the conditions for liquidation or you do not manually close the position, you can hold the position permanently.',
        p36: '1: The system will automatically close the position if the set value of Take Profit and Stop Loss is reached',
        p37: '2: The risk is too high, the system is forced to close the position',
        p38: 'What is the margin in contract transactions? ',
        p39: 'In contract transactions, users can participate in the sale and purchase of contracts based on the contract price and quantity, and the corresponding leverage multiples. The user will take up the margin when opening a position. The more the opening margin is, the higher the account risk will be.',
        p40: 'What are limit order and market order?  ',
        p41: 'Limit order refers to what price you expect to entrust the platform to trade, and market order refers to entrust the platform to trade at the current price directly. In the rules for opening positions, market orders are given priority over limit orders. If you choose a limit order, please open the position reasonably according to the current market price of the currency to avoid losses due to unreasonable opening prices.',
        p42: 'What does the risk level of contract transactions represent? ',
        p43: 'Risk degree is a risk indicator in your contract account. A risk degree equal to 100% is considered as a liquidation. We suggest that when your risk exceeds 50%, you need to open your position carefully to avoid losses due to liquidation. You can reduce your risk by replenishing the available funds of contract assets, or reducing your positions.',
        p44: 'Why is currency exchange necessary?  ',
        p45: 'The purpose of the exchange is to allow the smooth circulation of funds in different currencies in your assets, and the QCC obtained in the futures account can be freely converted into USDT for trading. USDT in other accounts can also be freely converted to QCC for trading.',
        p46: 'Why did the other party not receive the transfer in time after the transaction was transferred?  ',
        p47: 'First of all, please make sure that the payment method you fill in when you transfer money is exactly the same as that displayed on the order details page. Then please confirm whether your payment method is immediate or delayed. Finally, please contact your bank and payment institution for system maintenance or other reasons.',
        p48: 'How to buy USDT through the platform? ',
        p49: 'Method 1: Select the order you want to buy through the platform buy and sell list to buy and sell.',
        p50: 'Method 2: Click the publish button to publish a buy or sell order for quick transactions according to your needs. The system will quickly match you with buying and selling users.',
        p51: 'Note: If the purchase order is not paid after 15 minutes, the system will automatically cancel the order, please pay in time. If the order is cancelled more than 3 times on the same day, the transaction cannot be performed again on the same day, and the trading authority will be restored the next day.'
    },
    public: {
        zxkf: 'Online customer service',
        sure: 'Confirm',
        cancel: 'Cancel',
        RefreshSucceeded: 'Refresh succeeded',
        noMoreData: 'No more data available to read',
        pullingText: 'Pull to refresh ...',
        ReleaseText: 'Release to refresh ...',
        loadingText: 'Loading ...',
        noData: 'No record found',
        xq: 'Detail',
        submit: 'Submit',
        kefu: 'Online customer service'
    },
    ChangeType: {
        Upper: 'Deposit arrival',
        Lower: 'Withdrawal',
        Win: 'Profit',
        Bet: 'Bet',
        BuyMining: 'Mining',
        CoinWin: 'New token profit',
        SettleMining: 'Return on capital in mining',
        GiveInterest: 'Mining interest',
        BuyCoin: 'New token purchase',
        BuyCoinBack: 'Return from new token purchase',
        UsdtToBtc: 'UsdtToBtc',
        UsdtToEth: 'UsdtToEth',
        BtcToUsdt: 'BtcToUsdt',
        BtcToEth: 'BtcToEth',
        EthToUsdt: 'EthToUsdt',
        EthToBtc: 'EthToBtc',
        CoinThaw: 'New currency thaw',
        CoinFrozen: 'New currency Frozen',
        CoinCancel: 'Currency order cancel',
        CoinBuy: 'Currency Buy',
        CoinBuyEntrust: 'Currency Buy entrust',
        CoinSellEntrust: 'Currency Sell entrust',
        CoinSell: 'Currency Sell',
        ContractBuy: 'Contract orders,Deduction of margin',
        ContractCharge: 'Contract orders, less handling charges',
        ContractCancelToBond: 'Refund of deposit after contract cancellation',
        ContractCancelToCharge: 'Refund of service charge for contract cancellation',
        ContractCloseProfit: 'Contract closing income'
    },
    trade: {
        title: 'Exchange',
        Buy: 'Buy',
        Sell: 'Sell',
        pc: 'close',
        cb: 'cost',
        xj1: 'present price',
        yk: 'Profit and loss',
        xianjia: 'fixed price',
        jiage: 'price',
        shuliang: 'num',
        keyong: 'available',
        yonghuchicang: 'positions',
        fxlts: 'risk rate = position margin / customer equity multiplied by 100%, where customer equity equals to available funds of the account + initial transaction margin + floating profit and loss',
        fxl: 'Hazard Rate',
        dqwt: 'Open orders',
        name: 'name',
        leixing: 'type',
        all: 'all',
        shijian: 'time',
        long: 'long',
        short: 'short',
        cc: 'hold positions',
        zyjg: 'current best price',
        caozuo: 'operation',
        xj: 'fixed price',
        sj: 'market price',
        jiaoyie: 'amount',
        orderNo: 'orderNo',
        weituojia: 'price',
        chengjiaojia: 'price',
        chengjiaoe: 'deal price',
        weituo: 'mode',
        qbwt: 'all delegate',
        lswt: 'Order history',
        tips: 'pleace enter price',
        tips1: 'pleace enter num',
        Create: 'entrustment',
        Wait: 'transaction',
        Complete: 'Closed',
        Cancel: 'revoked',
        zsj: 'Stop loss price',
        zyj: 'Stop profit price',
        cczsy: 'Total income from positions',
        ykbl: 'Profit and loss ratio',
        bili: 'ratio',
        jyss: 'Number of transactions',
        bzj: 'Margin',
        sxf: 'Handling fee',
        gg: 'Leverage multiple',
        yjpc: 'close all',
        status: 'state',
    },
    auth: {
        title: 'Identity Authentication',
        title1: 'Identity Authentication',
        low: 'Basic authentication',
        high: 'Advanced authentication',
        success: 'Successfully Verified',
        tips: 'Please conduct primary authentication first',
        tips1: 'Please enter your real name',
        tips2: 'Please enter your ID number',
        tips3: 'Please upload your ID card (Front)',
        tips4: 'Please upload your ID card (Back)',
        tips5: 'Please upload a Photo of yourself holding the ID with the photo page',
        qrz: 'To verify',
        shz: 'Under review',
        rzsb: 'Verification failed',
        userName: 'Real Name',
        IDNumber: 'ID number',
        zmz: 'Photo of ID card (Front)',
        bmz: 'Photo on ID card (Back)',
        scz: 'Photo of yourself holding the ID with the photo page'
    },
    share: {
        title: 'My referral code',
        cp: 'Copy address',
        success: 'Copy successfully'
    },
    property: {
        Contract: 'Spot',
        Exchange: 'Second-contract',
        zzc: 'total assets',
        mhyzh: 'Second contract account',
        bbzh: 'Spot account',
        available: 'Available',
        OnOrders: 'Freeze',
        Estimated: 'Equivalent',
        cw: 'Financial Record',
        number: 'Number',
        record: 'Record',
        Transfer: 'Transfer',
        time: 'Time',
        dhsl: 'Amount of token to swap',
        tips: 'Please enter the amount of token to swap',
        qbdh: 'Swap all',
        dhhl: 'Exchange rate',
        ky: 'Available',
        kd: 'Available',
        sxfbl: 'Handling Fees (Rate)',
        sxf: 'Handling Fees'
    },
    statusLang: {
        Review: 'Review',
        Success: 'Success',
        Fail: 'Fail'
    },
    with: {
        title: 'Withdraw',
        history: 'History',
        ldz: 'Blockchain address',
        tbdz: 'Withdrawal address',
        tips: 'Please enter withdrawal address',
        tips1: 'Please enter withdrawals amount',
        tips2: 'please enter Fund password',
        tbsl: 'Withdrawals Amount',
        ky: 'Available',
        zjmm: 'Fund password',
        sxf: 'Handling fee',
        dz: 'Amount expected to be received'
    },
    tabBar: {
        home: 'Home',
        Quotation: 'Markets',
        Quotation2: 'Market quotation',
        Trade: 'Trade',
        Contract: 'Contract',
        assets: 'Assets',
        qhyy: 'switch language',
        zyjb: 'Lock in mining',
        jsjy: 'Fast trading',
        jryw: 'Financial business',
        hyjy: 'Contractual transaction',
        bbjy: 'Coin transaction'
    },
    home: {
        UserName: 'User Name',
        score: 'Score',
        kjmb: 'Quick purchase',
        zc: 'Support BTC, USDT, etc.',
        scwk: 'Lock-up Mining',
        sgxb: 'New Token Subcription',
        aqkx: 'Safe and reliable',
        aqkx1: 'The world’s leading digital asset trading platform',
        zfb: 'List of Surged Coins',
        dfb: 'Drop List',
        mc: 'Name',
        zxj: 'Lastest Price',
        zdb: 'Net Change',
        hy: 'Leverage',
        btyh: 'Normal user',
        sfyz: 'Identity Authentication',
        skfs: 'Payment method',
        share: 'My share link',
        kjcb: 'Quick deposit',
        kjtb: 'Quick withdrawal',
        qbdz: 'Wallet Address',
        bps: 'white paper',
        xgmm: 'change password',
        xgzfmm: 'change fund password',
        gywm: 'About',
        tcdl: 'Loqout'
    },
    quotation: {
        title: 'Markets',
        mc: 'Quantity',
        zxj: 'Latest Price',
        zdb: 'Net change',
        liang: 'Volume'
    },
    contract: {
        kcslfw: 'Opening Quantity Range:',
        gao: 'high',
        di: 'low',
        liang: '24 H volume',
        placeholder: 'Please enter the amount',
        SuccessfulPurchase: 'Purchase Successfully',
        smallMin: 'min',
        hour: 'hour',
        dayMin: 'day',
        Trend: 'Time',
        ccjl: 'Holding list',
        ddjl: 'Order list',
        sl: 'Quantity',
        gmj: 'Purchase Price',
        dqj: 'Current Price',
        yjyk: 'Estimated profit and loss',
        djs: 'Countdown',
        gbjg: 'Strike price',
        sc: 'Handling fees',
        pal: 'Profit and Loss',
        mhy: 'Second-contract',
        sj: 'Time',
        yll: 'Profit rate',
        mz: 'Long',
        md: 'Short',
        kcsl: 'Open position quantity',
        kcsj: 'Open position time',
        zhye: 'account balance',
        qc: 'cancel'
    },
    editPassword: {
        title: 'Change Password',
        jmm: 'old password',
        xmm: 'new password',
        qrmm: 'confirm password',
        placeholder: 'old password',
        placeholder1: 'set new password',
        placeholder2: 'Please enter a new password again',
        qrxf: 'OK'
    },
    withPass: {
        title: 'Withdrawal Password',
        jmm: 'Old password',
        xmm: 'New password',
        qrmm: 'Confirm password',
        placeholder: 'old password',
        placeholder1: 'set new password',
        placeholder2: 'Please enter a new password again',
        qrxf: 'OK',
        tips: 'Please enter an old password of at least 6 digits',
        tips1: 'Please enter a new password of at least 6 digits',
        tips2: 'Please enter your confirmation password',
        tips3: 'Two new password input is inconsistent',
        tips4: ' Password has been set successfully',
        confirm: 'Confirm'
    },
    newBe: {
        title: 'Subscription',
        title1: 'My subscription',
        scqx: 'Lock-up Period',
        day: 'Day',
        sy: 'Remaining',
        sdsg: 'History',
        sysl: 'Remaining quantity',
        gmsl: 'Purchase quantity',
        dj: 'Unit Price',
        tips: 'Please enter the purchase quantity',
        gmzj: 'Total purchase price',
        qbye: 'Wallet balance',
        fmzj: 'Total purchase price',
        gm: 'buy',
        sgsj: 'Subscription Time',
        Currency: 'Token Type',
        Request: 'Requested amount',
        Passing: 'Passed amount',
        MarketTime: 'Listing Time',
        sqz: 'In the application'
    },
    register: {
        title: 'Registration',
        lang: 'Language',
        send: 'send',
        Resend: 'Resend',
        Account: 'Account',
        Password: 'Password',
        ConfirmPassword: 'Confirm password',
        InvitationCode: 'Invitation code',
        btn: 'Register',
        amount: 'Account',
        mailbox: 'Mailbox',
        haveAccount: 'Already have an account？',
        Login: 'Login',
        placeholder: 'Please enter the email',
        placeholder0: 'Please enter the account',
        placeholder1: 'Please enter the password',
        placeholder2: 'Please enter the password again',
        placeholder3: 'Please enter the invitation code',
        placeholder4: 'Please enter the email verification code',
        placeholder5: 'Please enter the correct mailbox',
        success: 'Registration was successful',
        message: ' Password must be 6-16 characters long',
        message1: 'Confirm Password input has at least 6 characters',
        message2: ' Password input is inconsistent',
        message3: 'Please read and tick the agreement',
        agree: 'I agree',
        xy: 'User agreement and privacy policy',
        qr: 'Submit'
    },
    lockming: {
        title: 'Token Lockup',
        wkb: 'Mining Treasure',
        wkgjd: 'Mining is easier',
        qsljl: 'Earn reward easily',
        yjzsr: 'Estimated Total Income',
        ck: 'Reference annualized rate of return for the last three days',
        tips: 'Minimum',
        rlx: 'Daily interest',
        day: 'day',
        scqx: 'Lockup period',
        buy: 'buy',
        zysl: 'Amount of staking',
        qbye: 'Wallet Balance',
        finished: 'Complete',
        doing: 'In the progress',
        sl: 'Quantity',
        shouyilv: 'Rate',
        ks: 'Start time',
        js: 'End time'
    },
    login: {
        title: 'Login',
        lang: 'Language',
        Account: 'Account',
        Password: 'Password',
        btn: 'Login',
        placeholder1: 'Please enter password',
        placeholder: 'Please enter account number',
        fail: 'Please complete the form',
        ForgotPassword: 'Forgot password',
        NoAccount: 'No account yet？',
        Registration: 'Register',
        OtherLogin: 'Other login',
        server: 'Online Customer Service',
        jzmm: 'Remember password',
        success: 'Login successful',
        ageent: 'Read and agree to the',
        ageentTitle: 'service agreement',
        ageentFail: 'Please read and check the service agreement',
        Home: 'Home'
    },
    moneyAddr: {
        title: 'Wallet address',
        Currency: 'Currency',
        Address: 'Address',
        placeholder: 'Please select',
        DepositAddress: 'Deposit address',
        WalletAddress: 'Wallet address',
        Date: 'Date',
        add: 'Add wallet address',
        ChainAddress: 'chain address',
        qrcode: 'Wallet QR code',
        submit: 'submit',
        tips: 'Please select wallet type',
        tips1: 'Please select chain address',
        tips2: 'Please enter wallet address',
        tips3: 'Please upload wallet QR code',
        success: 'success'
    },
    recharge: {
        title: 'Recharge',
        title1: 'Recharge Details',
        title2: 'Withdrawal details',
        ls: 'history',
        td: 'Please select the following channel',
        bc: 'Save QR',
        address: 'Deposit address',
        copy: 'Copy address',
        num: 'Number of deposits',
        Upload: 'Upload picture',
        Submit: 'Submit',
        tip: 'Please enter amount',
        tip1: 'Please upload QR code',
        copySuc: 'Copied Successfully'
    },
    newChange: {
        num: 'Please enter the quantity',
        psd: 'Please enter the password',
        hq: 'Markets',
        bb: 'Help center',
        sec: 'Verified',
        ass: 'assets',
        pool: 'financial management',
        newc: 'About us',
        rechange: 'Recharge',
        with: 'Customer service',
        help: 'Help Center',
        a1: 'Financial',
        a2: 'Fnds in custody(USDT)',
        a3: 'Today Profits',
        a4: 'Cumulative earnings',
        a5: 'In progress',
        a6: 'Orders',
        a7: 'Rules',
        a8: 'Daily return',
        a9: 'Single limit',
        a10: 'Cycle',
        a11: 'Pecently(Daily Revenue)',
        a12: 'Single limit',
        a13: 'Custody amount：',
        a14: 'Subscribe',
        a15: 'Calculation of earnings',
        a16: 'Bricks are moved by hosting USDT to the platform,and the platform is professional team carries out the arbitrage of moving the bricks...',
        a17: 'One member deposited 10,000U on the platform,and selected a wealth management product with a period of 5 days and a daily rate of return of 0.3% to 0.5%.The daily highest:10000U x 0.5% = 50 U n That is 150U-250U income can be obtained after 5 days,the income is issued daily,and the issued income can be accessed at any time. The deposited principal is expired.After that,it will be ...',
        a18: 'About liquidated damages',
        a19: 'If you wish to transfer the unexpired principal,liquidated damages will be incurred.Liquidated damages = default settlement ratio * remaining days * mumber of investments. NExample:the product is default settlement ratio is 0.4%,Due in the remaining 3 days,the amount of investment is 1000,then liquidated damages = 0.4%*3...',
        a20: 'I want join'
    },
    msgbank: {
        msgTitle: 'Message',
        noticeTitle: 'Announcement',
        msgContent: 'You have unread messages',
        msgBtn: 'Go check',
        bankCard: 'Bank card',
        inputTips: 'Please enter complete information',
        submitSuccess: 'Submitted successfully',
        CardUserName: 'Account holder name',
        BankName: 'Opening Bank',
        OpenBank: 'Account Opening Branch',
        CardNo: 'Bank card number',
        noBindBankmsg: 'You have not linked your bank card yet',
        noBindBankBtn: 'Unbind',
        modify: 'modify',
    }
}