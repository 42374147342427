import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/rem'
import '@/styles/index.styl'
import 'vant/lib/index.css'
import * as filters from './filters'
import * as echarts from 'echarts'
import store from './store'
import VConsole from 'vconsole'

Vue.prototype.$vconsole = VConsole
Vue.prototype.$echarts = echarts

import VueI18n from 'vue-i18n'
import Vant from 'vant'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Vant)
  .use(VueI18n)
  .use(ElementUI)

const i18n = new VueI18n({
  // locale: localStorage.getItem('language') || 'zh', // 语言标识
  // messages: {
  //   zh: require('@/assets/lang/zh')
  //   // en: require('@/assets/lang/en'),
  //   // ft: require('@/assets/lang/ft'),
  //   // jp: require('@/assets/lang/jp')
  // }
})

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
