module.exports = {
    pcregister: {
        p1: '이미 계정이 있습니다?',
        dl: '로그인',
        zc: '등록하다',
        p4: '계정',
        p5: '계정을 입력하십시오',
        p6: '비밀번호를 입력 해주세요',
        p7: '확인 비밀번호를 입력해주세요',
        p8: '초대코드를 입력해주세요',
        p9: '동의한다',
        p10: '《사용자 계약 및 개인 정보 보호 정책》',
        p11: '우편',
        p12: '이메일을 입력해주세요',
        p13: '이메일 인증 코드를 입력해주세요',
        p14: '보내다',
        p15: '연도',
        p16: '월',
        p17: '하늘',
        p18: '시',
        p19: '분',
        p20: '초'
    },
    pclogin: {
        l1: '계정 없음?',
        l2: '등록하다',
        l3: '로그인',
        l4: '사용자 이름을 입력하십시오',
        l5: '비밀번호를 입력 해주세요',
        l6: '비밀번호를 잊으 셨나요',
        l7: '로그인'
    },
    pccontract: {
        ss: '시장 가격',
        xj: '가격 제한',
        gg: '지렛대'
    },
    pcrapidly: {
        zf: '증가하다',
        dd: '주문하다',
        bdsj: '현지 시각',
        ky: '사용 가능'
    },
    pchome: {
        global1: '세계 최고의 디지털 자산 거래 플랫폼',
        global2: '100개 이상의 국가에서 수백만 명의 사용자에게 안전하고 신뢰할 수 있는 디지털 자산 거래 및 자산 관리 서비스를 제공합니다.',
        global3: '안전하고 믿을 수 있는',
        global4: '5년의 디지털 자산 금융 서비스 경험. 분산 아키텍처 및 DDoS 공격 방지 시스템에 중점을 둡니다.',
        global5: '글로벌 생태 레이아웃',
        global6: '여러 비즈니스 형식을 통합하는 블록체인 생태계를 만들기 위해 많은 국가에 현지화된 거래 서비스 센터가 설립되었습니다.',
        global7: '사용자 우선',
        global8: '사전 보상 메커니즘을 구축하고 투자자 보호 기금을 설정하십시오.',
        whever1: '언제 어디서나 멀티플랫폼 단말 거래',
        whever2: 'iOS, Android 및 Windows를 포함한 여러 플랫폼을 포괄하고 전체 비즈니스 기능을 지원합니다.',
        register1: '등록',
        register3: '글로벌 계정 거래 시작',
        register2: '지금 등록하세요'
    },
    pcbottom: {
        box1: '세계 최고의',
        box2: '최고의 팀 중 하나',
        box3: '더',
        box4: '우리에 대해',
        box5: '백지',
        box6: '제공하다',
        box7: '다운로드',
        box8: '일반적인 문제',
        box9: '온라인 서비스',
        box10: '규제',
        box11: '사용자 계약',
        box12: '개인 정보 정책',
        box13: '법적 고지',
        box14: '연결하다'
    },
    pcAbout_us: {
        t1: '강의',
        t2: '우리, 7년의 길',
        t3: '2015년 탄생한 이래 전업 기술과 채널 구축 서비스로 블록체인 서비스에 정진하고 있습니다.',
        t4: '2014년부터 2017년까지 나는 "꾸준한 성장"의 상승기를 경험했다. 진짜 실력을 키웠다. 수많은 성공적인 파트너가 업계에서 낮은 평판을 얻었습니다. 2022년에 우리는 완전히 새로운 플랫폼 V3.0을 보유하고 전 세계 100개 이상의 국가에서 사업을 수행하기 위해 새로운 합자회사 BitGoldEX를 설립할 것입니다.',
        t5: '우리는 누구를 찾고 있습니까',
        t6: '처음부터 오늘까지',
        t7: '나는 어려움이 있고 나와 같은 취향을 가진 서비스 제공자를 찾고 있었고 몇 년 동안 수십 개의 서비스 제공자의 문제를 성공적으로 해결했습니다.. ',
        t8: '오늘날의 합리성 속에서 좋은 도우미, 자신을 깨닫고 나아가기 위해 함께 노력할 수 있는 파트너를 찾고 싶다면 아마도 우리와 대화할 수 있을 것입니다.',
        t9: '당신이 찾고있는 사람이 당신을 찾고 있다고 믿으십시오.',
        t10: '제공하다',
        t11: '세일즈맨은 없지만 사업은 있다',
        t12: '친구가 당신에게 사업을 소개한다는 것은 당신이 좋은 사람이라는 뜻입니다. 동료가 당신에게 사업을 소개한다면 그것은 당신이 좋은 평판을 가지고 있다는 것을 의미합니다. 클라이언트가 당신에게 사업을 소개할 때 그것은 당신이 신뢰할 수 있다는 것을 증명합니다.',
        t13: '영혼',
        t14: '우린 벽돌이야 어디로 갈 필요 없어',
        t15: '고객마다 요구 사항이 다릅니다. 다른 제품에는 다른 특성이 있습니다. 다른 시간에는 다른 목표가 있습니다. 우리 존재의 의미는 다양한 서비스 공급자의 개념, 시스템 및 요구 사항에 적응하고 다양한 문제를 해결하는 것입니다.',
        t16: '우리는 생각과 기능을 갖춘 벽돌이 될 준비가 되어 있으며, 필요한 곳이라면 어디든지 달려가겠습니다.'
    },
    pcproblem: {
        p1: '일반적인 문제',
        p2: '변환된 자산의 수가 변경되는 이유는 무엇입니까?',
        p3: '자산의 등가 계산은 현재 보유하고 있는 디지털 통화를 USDT로 환산한 값으로, 디지털 자산의 가격 변동으로 인해 변경됩니다. 디지털 자산의 수는 변경되지 않았습니다.',
        p4: '플랫폼 거래에서 잊어버린 자금 암호를 처리하는 방법',
        p5: '플랫폼 거래 자금 비밀번호를 잊은 경우 "내-설정-펀드 비밀번호 설정 클릭"으로 이동하거나 고객 서비스에 문의하여 재설정하십시오.',
        p6: '거래 구매 후 돈은 어디로 갑니까?',
        p7: '구매 주문이 완료되면 자금은 자산의 명목 화폐 계정으로 이동합니다. 계약 거래 또는 선물 거래를 위해 자산을 구매해야 하는 경우 자금 이체 모듈로 이동하여 이체하십시오.',
        p8: '닉네임 변경 횟수를 제한하는 이유',
        p9: '거래 보안을 위해 현재 닉네임은 한 번만 수정할 수 있도록 제한하고 있습니다.',
        p10: '전송은 무엇을 의미합니까? 왜 전송',
        p11: '계정 간 자금의 독립성을 보장하고 합리적인 위험 관리를 용이하게 하기 위해 각 주요 거래 모듈의 계정을 분할합니다. 이체란 거래 계좌 간의 자산 전환 과정을 말합니다.',
        p12: '사용자 계약의 계정 자산은 어떻게 계산됩니까?',
        p13: '계약 계정 순 가치 = 포지션 증거금 + 포지션 변동 손익 + 당좌 계정 가용 금액',
        p14: '포지션을 열 때 Stop Loss 및 Take Profit 설정 값은 무엇입니까? 어떻게 설정해야 할까요?',
        p15: '손익의 상한선으로 손익을 취하십시오. 설정된 스톱 이익 및 스톱 로스 금액에 도달하면 시스템이 자동으로 포지션을 닫습니다. 계약을 구매할 때 위험 관리에 사용할 수 있습니다. 이익 실현 금액의 절반 설정: 금액 x 수량 x 레버리지 배수 증가, 손절매 설정. 실제 자산에 따라 설정하고 위험을 합리적으로 관리하는 것이 좋습니다.',
        p16: '계약 위험을 줄이는 방법은 무엇입니까?',
        p17: '자금 이체를 통해 남은 계좌의 가용 자산을 계약 계좌로 이체하거나, 포지션을 축소하여 위험을 줄일 수 있습니다.',
        p18: '계약 개시 수량은 무엇을 의미합니까?',
        p19: '미결제 금액은 구매하려는 통화의 금액을 나타냅니다. 예: BTC/USDT 거래 쌍의 시작 페이지에서 롱을 선택하고 입력 가격은 1000USDT이고 입력 수량은 10BTC입니다. 즉, 10 BTC를 구매할 것으로 예상하고 단가는 1000USDT입니다.',
        p20: '계약서의 취급 수수료는 어떻게 계산됩니까?',
        p21: '취급수수료 = 개봉가 * 개봉수량 * 취급수수료율',
        p22: '강제청산 시 주의사항',
        p23: '위험 수준은 자산의 위험을 측정한 것입니다. 위험 수준이 100%일 때 귀하의 포지션은 종료된 것으로 간주되며, 위험 수준 = (보유 마진 / 계약 계정 자본) * 100%, 사용자가 포지션을 초과하는 것을 방지하기 위해 시스템은 위험 수준 비율을 조정합니다. 위험 수준이 시스템에서 설정한 위험 값에 도달하면 시스템은 강제로 포지션을 닫습니다. 예를 들어, 시스템에서 설정한 조정 비율은 10%이고 위험도가 90% 이상이면 시스템에서 모든 포지션을 강제로 청산합니다.',
        p24: '계약 거래 규칙은 무엇입니까?',
        p25: '거래 유형',
        p26: '거래 유형은 롱(매수) 및 숏(매도) 방향으로 나뉩니다.',
        p27: '롱(강세) 매수는 현재 지수가 오를 가능성이 높다고 믿고 일정한 금액을 매수해 자신이 설정한 가격이나 현재 시세로 계약을 맺으려는 것을 의미합니다.',
        p28: '공매도(약세)는 현재 지수가 하락할 수 있다고 믿고 일정 수의 새로운 계약을 설정한 가격 또는 현재 시장 가격으로 매도하고자 하는 것을 의미합니다.',
        p29: '주문방법',
        p30: '지정가 주문: 주문의 가격과 수량을 지정해야 합니다.',
        p31: '시장가 주문: 주문 수량만 설정하면 되며, 가격은 현재 시장 가격입니다.',
        p32: '위치',
        p33: '포지션 오픈을 위해 제출한 주문이 완료된 시점을 포지션이라고 합니다.',
        p34: '계약 인도 문제',
        p35: '플랫폼 계약은 배송 시간이 없는 영구 계약입니다. 시스템이 강제 청산 조건을 충족하지 않거나 수동으로 포지션을 닫지 않는 한 포지션을 영구적으로 유지할 수 있습니다. 시스템 종료',
        p36: '1: 손절매 및 손절매 설정 값에 도달하면 시스템이 자동으로 포지션을 청산합니다.',
        p37: '2: 위험이 너무 높음, 시스템 강제 청산',
        p38: '계약 거래의 증거금은 얼마입니까?',
        p39: '계약 거래에서 사용자는 계약 가격과 수량, 해당 레버리지에 따라 계약 거래에 참여할 수 있습니다. 사용자가 포지션을 열면 마진이 채워집니다. 개시 마진이 클수록 계정의 위험이 높아집니다.',
        p40: '지정가 주문과 시장가 주문이란 무엇입니까?',
        p41: '지정가 주문은 플랫폼에 위탁하여 거래를 수행할 것으로 예상되는 가격을 말하며, 시장가 주문은 현재 가격으로 거래를 수행하도록 플랫폼에 직접 위탁하는 것을 의미합니다. 시장 주문은 주문 개시 규칙에서 지정가 주문보다 우선합니다. 지정가 주문을 선택한 경우 불합리한 개시 가격으로 인한 손실을 방지하기 위해 통화의 현재 시장 가격에 따라 합리적으로 포지션을 오픈하십시오.',
        p42: '계약 거래의 위험 수준은 무엇을 나타냅니까?',
        p43: '위험은 계약 계정의 위험 지표입니다. 100%에 해당하는 위험 수준은 청산된 것으로 간주됩니다. 위험이 50%를 초과하면 포지션을 닫아 손실이 발생하지 않도록 신중하게 포지션을 오픈해야 합니다. 계약 자산에서 사용 가능한 자금을 보충하거나 포지션을 줄임으로써 위험을 줄일 수 있습니다.',
        p44: '왜 환전이 필요한가요?',
        p45: '거래소의 목적은 귀하의 자산에서 다른 통화로 자금이 원활하게 순환되도록 하는 것이며, 선물 계정에서 얻은 QCC는 거래를 위해 자유롭게 USDT로 전환할 수 있습니다. 다른 계정의 USDT도 트랜잭션을 위해 자유롭게 QCC로 변환할 수 있습니다.',
        p46: '송금 후 상대방이 송금을 제때 받지 못한 이유는 무엇인가요?',
        p47: '먼저 송금 시 입력하는 결제수단이 주문 상세페이지에 표시된 결제수단과 정확히 일치하는지 확인해주세요. 그런 다음 결제 방법이 즉시 결제인지 지연 결제인지 확인하십시오. 마지막으로 시스템 유지 보수 또는 기타 이유로 은행 및 결제 기관에 문의하십시오.',
        p48: '플랫폼을 통해 USDT를 구매하는 방법은 무엇입니까?',
        p49: '방법 1: 플랫폼의 매수 및 매도 목록을 통해 사고자 하는 주문을 선택하여 매수 또는 매도합니다.',
        p50: '방법 2: 게시 버튼을 클릭하여 빠른 거래를 위해 필요에 따라 매수 또는 매도 주문을 게시합니다. 시스템은 구매자와 판매자를 신속하게 연결해 드립니다.',
        p51: '참고: 15분 후에도 구매 주문이 지불되지 않으면 시스템이 자동으로 주문을 취소하므로 시간 내에 지불하십시오. 당일 주문취소가 3회 이상일 경우 당일 다시 거래가 불가능하며 거래권한은 익일 복구됩니다.'
    },
    public: {
        zxkf: '온라인 서비스',
        sure: '확인하다',
        cancel: '취소',
        RefreshSucceeded: '성공적으로 새로 고침',
        noMoreData: '더 이상 데이터가 없습니다',
        pullingText: '아래로 당겨 새로고침 ...',
        ReleaseText: '새로 고침 ...',
        loadingText: '로드 중 ...',
        noData: '어떤 기록도 없다',
        xq: '세부',
        submit: '확인하다',
        kefu: '온라인 서비스'
    },
    ChangeType: {
        Upper: '시스템 재충전',
        Lower: '시스템 철수',
        Win: '이익',
        Bet: '투자하다',
        BuyMining: '채광',
        CoinWin: '싱가포르 달러 이익',
        SettleMining: '채굴 원금 반환',
        GiveInterest: '채굴 수입',
        BuyCoin: 'SGD 획득 불가',
        BuyCoinBack: 'SGD 구독 반환',
        UsdtToBtc: 'USDT에서 BTC로',
        UsdtToEth: 'Usdt에서 Eth로',
        BtcToUsdt: 'Btc 에서 Usdt',
        BtcToEth: 'BTC에서 Eth로',
        EthToUsdt: 'ETH에서 Usdt로',
        EthToBtc: 'ETH에서 Btc로',
        CoinFrozen: '새로운 통화 복권 동결',
        CoinThaw: 'SGD 동결 해제',
        CoinCancel: '통화 주문 취소',
        CoinBuy: '통화 구매',
        CoinBuyEntrust: '코인 구매 주문',
        CoinSellEntrust: '토큰 판매 주문',
        CoinSell: '통화 매도',
        ContractBuy: '계약 주문, 차감 증거금',
        ContractCharge: '계약 발주, 수수료 공제',
        ContractCancelToBond: '계약해지 보증금 반환',
        ContractCancelToCharge: '계약 해제 환불 수수료',
        ContractCloseProfit: '계약청산 수입'
    },
    trade: {
        title: '통화',
        Buy: '구입',
        Sell: '팔다',
        pc: '포지션을 청산하다',
        cb: '비용',
        xj1: '현재 가격',
        yk: '이익과 손실',
        xianjia: '가격 제한',
        jiage: '가격',
        shuliang: '수량',
        keyong: '사용 가능',
        yonghuchicang: '사용자 위치',
        fxlts: '위험률 = 포지션 증거금/고객 자산에 100%를 곱한 값입니다. 여기서 고객 자산은 계정 가용 자금 + 초기 거래 증거금 + 변동 손익과 같습니다.',
        fxl: '위험률',
        dqwt: '현재 커미션',
        all: '모두',
        name: '이름',
        shijian: '시각',
        zyjg: '현재 최고가로 거래',
        caozuo: '작동하다',
        xj: '가격 제한',
        cc: '위치',
        leixing: '유형',
        long: '더 많은 일을 할',
        short: '짧은',
        sj: '시장 가격',
        jiaoyie: '거래 금액',
        orderNo: '주문 번호',
        weituojia: '수수료 가격',
        chengjiaojia: '최종 가격',
        chengjiaoe: '회전율',
        weituo: '위탁방법',
        qbwt: '모두 맡기다',
        lswt: '역사적 위원회',
        tips: '정확한 가격을 입력해주세요',
        tips1: '수량을 입력해주세요',
        Create: '맡겨진',
        Wait: '거래 중',
        Complete: '닫힌 위치',
        Cancel: '취소 된',
        zsj: '중지 가격',
        zyj: '이익실현',
        cczsy: '포지션 총 이익',
        ykbl: '손익비율',
        bili: '비율',
        jyss: '거래 제비',
        bzj: '여유',
        sxf: '수수료',
        gg: '영향력',
        yjpc: '클릭 한 번으로 포지션 청산',
        status: '상태',
    },
    auth: {
        title: '입증',
        title1: '입증',
        low: '기본 인증',
        high: '고급 인증',
        success: '인증 성공',
        tips: '1차 인증을 먼저 해주세요',
        tips1: '실명을 입력해주세요',
        tips2: '주민등록번호를 입력해주세요',
        tips3: '신분증의 앞면 사진을 업로드하세요.',
        tips4: '신분증 뒷면 사진을 업로드해주세요.',
        tips5: '손에 들고 찍은 사진을 올려주세요',
        qrz: '인증하러 가기',
        shz: '검토 중',
        rzsb: '인증 실패',
        userName: '실명',
        IDNumber: '식별 번호',
        zmz: '신분증 앞면 사진',
        bmz: '신분증 뒷면 사진',
        scz: '손 사진'
    },
    share: {
        title: '내 프로모션 코드',
        cp: '주소 복사',
        success: '성공적으로 복사'
    },
    property: {
        Contract: '통화',
        Exchange: '두 번째 계약',
        zzc: '총 자산 등가물',
        mhyzh: '두 번째 계약 계정',
        bbzh: '통화 계정',
        available: '사용 가능',
        OnOrders: '얼다',
        Estimated: '전환하다',
        cw: '재무 기록',
        number: '수량',
        record: '기록',
        Transfer: '옮기다',
        time: '시각',
        dhsl: '교환 금액',
        tips: '환전금액을 입력해주세요',
        qbdh: '모두 교환',
        dhhl: '환율',
        ky: '사용 가능',
        kd: '사용 가능',
        sxfbl: '수수료 비율',
        sxf: '수수료'
    },
    statusLang: {
        Review: '검토',
        Success: '성공',
        Fail: '불합격'
    },
    with: {
        title: '철회하다',
        history: '출금기록',
        ldz: '체인 주소',
        tbdz: '출금 주소',
        tips: '출금주소를 입력해주세요',
        tips1: '출금금액을 입력해주세요',
        tips2: '결제 비밀번호를 입력해주세요',
        tbsl: '출금금액',
        ky: '사용 가능',
        zjmm: '결제 비밀번호',
        sxf: '수수료',
        dz: '예상 금액'
    },
    tabBar: {
        home: '첫 장',
        Quotation: '인용 부호',
        Quotation2: '시장',
        Trade: '통화',
        Contract: '두 번째 계약',
        assets: '자산',
        qhyy: '언어 전환',
        zyjb: '락 마이닝',
        jsjy: '익스트림 트랜잭션',
        jryw: '금융 서비스',
        hyjy: '계약 거래',
        bbjy: '커런시 트레이드'
    },
    home: {
        UserName: '계정',
        kjmb: '빠르게 코인 구매',
        score: '신용 점수',
        zc: 'BTC, USDT 등 지원',
        scwk: '락 마이닝',
        sgxb: 'KRW 구독',
        aqkx: '안전하고 신뢰할 수 있는',
        aqkx1: '세계 최고의 디지털 자산 거래 플랫폼',
        zfb: '이득 목록',
        dfb: '낙폭 순위',
        mc: '이름',
        hy: '계약',
        zxj: '최신 가격',
        zdb: '견적 변경',
        sfyz: '입증',
        skfs: '지불 방법',
        share: '내 공유 링크',
        kjcb: '빠른 입금',
        kjtb: '빠른 인출',
        qbdz: '지갑 주소',
        bps: '백지',
        xgmm: '비밀번호 변경',
        xgzfmm: '결제 비밀번호 수정',
        gywm: '우리에 대해',
        tcdl: '그만두다'
    },
    quotation: {
        title: '인용 부호',
        mc: '수량',
        zxj: '최신 가격',
        zdb: '견적 변경',
        liang: '수량'
    },
    contract: {
        kcslfw: '개방 수량 범위:',
        gao: '높은',
        di: '낮은',
        liang: '24시간 거래량',
        placeholder: '수량을 입력해주세요',
        SuccessfulPurchase: '성공적인 구매',
        smallMin: '분',
        hour: '시',
        dayMin: '하늘',
        Trend: '시분할',
        ccjl: '직위 목록',
        ddjl: '주문 기록',
        sl: '수량',
        gmj: '구매 가격',
        dqj: '현재 가격',
        yjyk: '예상 손익',
        djs: '카운트다운',
        gbjg: '최종 가격',
        sc: '수수료',
        pal: '이익과 손실',
        mhy: '두 번째 계약',
        sj: '시각',
        yll: '수익성',
        mz: '사다',
        md: '공매도',
        kcsl: '개봉 수량',
        kcsj: '개장 시간',
        zhye: '계정 잔액',
        qc: '취소'
    },
    editPassword: {
        title: '비밀번호 변경',
        jmm: '기존 비밀번호',
        xmm: '새 비밀번호',
        qrmm: '비밀번호 확인',
        placeholder: '기존 비밀번호',
        placeholder1: '새 비밀번호 설정',
        placeholder2: '새 비밀번호를 다시 입력해주세요',
        qrxf: '확인하다'
    },
    withPass: {
        title: '출금 비밀번호',
        jmm: '기존 비밀번호',
        xmm: '새 비밀번호',
        qrmm: '비밀번호 확인',
        placeholder: '기존 비밀번호',
        placeholder1: '새 비밀번호 설정',
        placeholder2: '새 비밀번호를 다시 입력해주세요',
        qrxf: '확인하다',
        tips: '이전 비밀번호를 6자 이상 입력하세요.',
        tips1: '6자 이상의 새 비밀번호를 입력하세요.',
        tips2: '정확한 확인 비밀번호를 입력해주세요',
        tips3: '두 번 입력한 새 비밀번호가 일치하지 않습니다.',
        tips4: '비밀번호가 성공적으로 설정되었습니다.',
        confirm: '확인하다'
    },
    newBe: {
        title: 'KRW 구독',
        title1: '내 구독',
        scqx: '락업 기간',
        day: '하늘',
        sy: '남은',
        sdsg: '기록',
        sysl: '남은 금액',
        gmsl: '구매 수량',
        dj: '단가',
        tips: '구매수량을 입력해주세요',
        gmzj: '총 구매 가격',
        qbye: '지갑 잔액',
        fmzj: '총 구매 가격',
        gm: '구입하다',
        sgsj: '구독 시간',
        Currency: '통화',
        Request: '신청 수',
        Passing: '패스 수량',
        MarketTime: '출시 시간',
        sqz: '신청 중'
    },
    register: {
        title: '등록하다',
        lang: '언어',
        send: '보내다',
        Resend: '재전송',
        Account: '계정',
        Password: '비밀번호',
        ConfirmPassword: '비밀번호 확인',
        InvitationCode: '초대 코드',
        btn: '등록하다',
        amount: '계정',
        mailbox: '우편',
        haveAccount: '이미 계정이 있습니까?',
        Login: '로그인',
        placeholder: '이메일을 입력해주세요',
        placeholder0: '계정을 입력하십시오',
        placeholder1: '비밀번호를 입력 해주세요',
        placeholder2: '확인 비밀번호를 입력해주세요',
        placeholder3: '초대코드를 입력해주세요',
        placeholder4: '이메일 인증 코드를 입력해주세요',
        placeholder5: '유효한 이메일을 입력하십시오',
        success: '등록 성공',
        message: '비밀번호 6자 이상',
        message1: '비밀번호를 6자 이상 확인하세요.',
        message2: '두 개의 암호가 일치하지 않습니다',
        message3: '동의서를 읽고 체크해주세요',
        agree: '동의한다',
        xy: '사용자 계약 및 개인 정보 보호 정책',
        qr: '확인하다'
    },
    lockming: {
        title: '잠금 목록',
        wkb: '광산 보물',
        wkgjd: '채굴이 더 쉬워졌습니다',
        qsljl: '리워드 받기 쉬움',
        yjzsr: '총 예상 이익',
        ck: '지난 3일 동안의 참조 연간화',
        tips: '적어도 ',
        rlx: '일일 이자',
        day: '하늘',
        scqx: '락업 기간',
        buy: '구입하다',
        zysl: '약정 금액',
        qbye: '지갑 잔액',
        finished: '마치다',
        doing: '가공...',
        sl: '수량',
        shouyilv: '수익률',
        ks: '시작 시간',
        js: '종료 시간'
    },
    login: {
        title: '로그인',
        lang: '언어',
        Account: '계정',
        Password: '비밀번호',
        btn: '로그인',
        placeholder1: '비밀번호를 입력 해주세요',
        placeholder: '계정을 입력하십시오',
        fail: '양식을 작성해주세요',
        ForgotPassword: '비밀번호를 잊으 셨나요',
        NoAccount: '아직 계정이 없으신가요?',
        Registration: '등록하다',
        server: '온라인 서비스',
        jzmm: '암호 기억',
        success: '성공적 로그인',
        Home: '첫 장'
    },
    moneyAddr: {
        title: '지갑 주소',
        Currency: '통화',
        Address: '주소',
        placeholder: '선택하십시오',
        DepositAddress: '입금 주소',
        WalletAddress: '지갑 주소',
        Date: '시각',
        add: '지갑 주소 추가',
        ChainAddress: '체인 주소',
        qrcode: '지갑 QR 코드',
        submit: '확인하다',
        tips: '지갑 유형을 선택하세요.',
        tips1: '체인 주소 유형을 선택하세요.',
        tips2: '지갑주소를 입력해주세요',
        tips3: '지갑 QR코드를 업로드해주세요',
        success: '성공'
    },
    recharge: {
        title: '재충전',
        title1: '충전 내용',
        title2: '현금 인출 명세',
        ls: '충전 내용',
        td: '다음 충전 채널을 선택하세요.',
        bc: 'QR 코드 저장',
        address: '입금 주소',
        copy: '주소 복사',
        num: '입금 금액',
        Upload: '결제 세부정보 스크린샷 업로드',
        Submit: '확인하다',
        tip: '수량을 입력해주세요',
        tip1: '스크린샷을 올려주세요',
        copySuc: '성공적으로 복사'
    },
    newChange: {
        num: '수량을 입력해주세요',
        psd: '비밀번호를 입력 해주세요',
        hq: '인용 부호',
        bb: '지원 센터',
        sec: '검증됨',
        ass: '자산',
        pool: '재무 관리',
        newc: '우리에 대해',
        rechange: '재충전',
        with: '고객 서비스',
        a1: '금융',
        a2: '보관금(USDT)',
        a3: '오늘 수익',
        a4: '누적 수익',
        a5: '진행 중',
        a6: '주문',
        a7: '규칙',
        a8: '매일 반납',
        a9: '단일 제한',
        a10: '주기',
        a11: '페센트리(일매출)',
        a12: '단일 제한',
        a13: '보관 금액：',
        a14: '구독',
        a15: '수입 계산',
        a16: '플랫폼에 USDT를 호스팅하여 브릭을 이동하고, 플랫폼은 브릭 이동의 차익거래를 전문팀이 수행합니다...',
        a17: '한 회원이 플랫폼에 10,000U를 예치하고, 5일간의 기간과 일일 수익률 0.3%~0.5%의 자산 관리 상품을 선택했습니다.일 최고:10000U x 0.5% = 50 U n That 150U-250U 수입은 5일 후에 얻을 수 있으며 수입은 매일 발행되며 발행된 수입은 언제든지 액세스할 수 있습니다. 예치된 원금은 만기입니다. 그 이후에는...',
        a18: '약정 손해배상에 대하여',
        a19: '만기되지 않은 원금을 양도하려면 손해배상액이 발생합니다. 손해배상액 = 채무불이행 비율 * 남은 일수 * 투자 횟수. N예: 제품의 기본 정산 비율은 0.4%이며, 남은 3일 동안 만기일은 투자 금액이 1000이며, 그러면 손해배상금 = 0.4%*3...',
        a20: '가입하고 싶어요',
        help: '지원 센터'
    },
    msgbank: {
        msgTitle: '메시지',
        noticeTitle: '공고',
        msgContent: '읽지 않은 메시지가 있습니다',
        msgBtn: '보러 가기',
        bankCard: '은행카드',
        inputTips: '전체 정보를 입력하십시오',
        submitSuccess: '제출 성공',
        CardUserName: '개소자 이름',
        BankName: '개구은행',
        OpenBank: '개호 지점',
        CardNo: '은행카드 번호',
        noBindBankmsg: '당신은 아직 은행카드를 바인딩하지 않았습니다',
        noBindBankBtn: '바인딩하러 가기',
        modify: '수정',
    }
}