import Vue from 'vue'
import VueRouter from 'vue-router'
// import { Token } from '@/utils/mm'
// import pchome from './viewspc/home/index.vue'

Vue.use(VueRouter)

let pcrouter = [{
        path: '/',
        name: 'pchome',
        // component: pchome,
        redirect: '/index'
    },
    // {
    //   path: '/home',
    //   name: 'pchome',
    //   component: pchome
    // },
    // {
    //   path: '/quotation',
    //   name: 'quotation',
    //   component: () => import('./viewspc/quotation/index.vue')
    // }

    {
        path: '/index',
        component: () =>
            import ('@/viewspc/index.vue'),
        children: [{
                path: '',
                redirect: 'home'
            },
            {
                path: 'home',
                name: 'Home',
                component: () =>
                    import ('@/viewspc/home/index.vue'),
                meta: {
                    activeMenu: 'home'
                }
            },
            {
                path: 'quotation',

                component: () =>
                    import ('./viewspc/quotation/index.vue'),
                meta: {
                    activeMenu: 'quotation'
                }
            },
            {
                path: 'rapidly',
                name: 'rapidly',
                component: () =>
                    import ('./viewspc/rapidly/index.vue'),
                meta: {
                    activeMenu: 'rapidly'
                }
            },
            {
                path: 'contract',
                component: () =>
                    import ('./viewspc/contract/index.vue'),
                meta: {
                    activeMenu: 'contract'
                }
            },
            {
                path: 'trade',
                component: () =>
                    import ('./viewspc/trade/index.vue'),
                meta: {
                    activeMenu: 'trade'
                }
            },
            {
                path: 'pledge',
                component: () =>
                    import ('./viewspc/pledge/index.vue'),
                meta: {
                    activeMenu: 'pledge'
                }
            },
            {
                path: 'About_us',
                component: () =>
                    import ('./viewspc/About_us/index.vue')
            },
            {
                path: 'Bps',
                component: () =>
                    import ('./viewspc/Bps/index.vue')
            },
            {
                path: 'problem',
                component: () =>
                    import ('./viewspc/problem/index.vue')
            },
            {
                path: 'agreement',
                name: 'agreement',
                component: () =>
                    import ('./viewspc/agreement/index.vue')
            },
            {
                path: 'Privacy',
                component: () =>
                    import ('./viewspc/Privacy/index.vue')
            },
            {
                path: 'Legal',
                component: () =>
                    import ('./viewspc/Legal/index.vue')
            },
            {
                path: 'login',
                name: 'Login',
                component: () =>
                    import ('./viewspc/login/index.vue')
            },
            {
                path: 'register',
                component: () =>
                    import ('./viewspc/register/index.vue')
            },
            {
                path: 'history',
                name: 'lokhistory',
                component: () =>
                    import ('./viewspc/pledge/history.vue')
            },
            {
                path: '/detail',
                name: 'Detail',
                meta: { tx: 1 },
                component: () =>
                    import ('@/viewspc/detail')
            }
        ]
    },
    {
        path: '/l-entrust',
        name: 'LEntrust',
        meta: { tx: 1 },
        component: () =>
            import ('@/viewspc/contract/entrust')
    },
    {
        path: '/entrust',
        name: 'Entrust',
        meta: { tx: 1 },
        component: () =>
            import ('@/viewspc/trade/entrust')
    },
    {
        path: '/lockming-history',
        name: 'LockmingHistory',
        meta: { tx: 3 },
        component: () =>
            import ('@/viewspc/pledge/history')
    }
]

let routes = [{
        path: '/',
        name: 'index',
        meta: { tx: 1 },
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/index')
    },
    {
        path: '/trade',
        name: 'Trade',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/trade')
    },
    {
        path: '/login',
        name: 'Login',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/login')
    },
    {
        path: '/entrust',
        name: 'Entrust',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/trade/entrust')
    },
    {
        path: '/l-entrust',
        name: 'LEntrust',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/leverage/entrust')
    },
    {
        path: '/kLine',
        name: 'KLine',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/trade/kline')
    },
    {
        path: '/l-kLine',
        name: 'LKLine',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/leverage/kline')
    },
    {
        path: '/detail',
        name: 'Detail',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/detail')
    },
    {
        path: '/register',
        name: 'Register',
        meta: { tx: 2 },
        component: () =>
            import ('@/views/register')
    },
    {
        path: '/server',
        name: 'Server',
        meta: { tx: 2 },
        component: () =>
            import ('@/views/server')
    },
    {
        path: '/quotation',
        name: 'Quotation',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/quotation')
    },
    {
        path: '/product',
        name: 'Product',
        meta: { tx: 9 },
        component: () =>
            import ('@/views/product')
    },
    {
        path: '/with',
        name: 'With',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/with')
    },
    {
        path: '/with-form',
        name: 'WithForm',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/with/form')
    },
    {
        path: '/withFormBank',
        name: 'WithFormBank',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/with/formBank')
    },
    {
        path: '/leverage',
        name: 'Leverage',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/leverage')
    },

    {
        path: '/l-record',
        name: 'LRecord',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/leverage/record')
    },
    {
        path: '/money-addr',
        name: 'MoneyAddr',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/moneyAddr')
    },
    {
        path: '/money-addr-create',
        name: 'MoneyAddrCreate',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/moneyAddr/create')
    },
    {
        path: '/with-history',
        name: 'WithHistory',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/with/history')
    },
    {
        path: '/lockming',
        name: 'Lockming',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/lockming')
    },
    {
        path: '/lockming-history',
        name: 'LockmingHistory',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/lockming/history')
    },
    {
        path: '/new-be',
        name: 'NewBe',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/new-be')
    },
    {
        path: '/new-be-history',
        name: 'NewBeHistory',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/new-be/history')
    },
    {
        path: '/property',
        name: 'Property',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/property')
    },
    {
        path: '/transfer',
        name: 'Transfer',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/property/transfer')
    },
    {
        path: '/account',
        name: 'Account',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/property/account')
    },
    {
        path: '/userAuth',
        name: 'UserAuth',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/mine/userAuth')
    },
    {
        path: '/collectMoney',
        name: 'CollectMoney',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/mine/collectMoney')
    },
    {
        path: '/auth',
        name: 'Auth',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/mine/auth')
    },
    {
        path: '/high-auth',
        name: 'HighAuth',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/mine/highAuth')
    },
    {
        path: '/share',
        name: 'Share',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/share')
    },

    {
        path: '/recharge-form',
        name: 'RechargeForm',
        meta: { tx: 2 },
        component: () =>
            import ('@/views/recharge/form')
    },
    {
        path: '/recharge-history',
        name: 'rechargeHistory',
        meta: { tx: 2 },
        component: () =>
            import ('@/views/recharge/history')
    },
    {
        path: '/recharge',
        name: 'Recharge',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/recharge')
    },
    {
        path: '/profit',
        name: 'Profit',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/profit')
    },

    {
        path: '/bank',
        name: 'Bank',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/bank')
    },
    {
        path: '/edit-password',
        name: 'EditPassword',
        meta: { tx: 2 },
        component: () =>
            import ('@/views/edit-password')
    },
    {
        path: '/edit-with-password',
        name: 'EditWithPassword',
        meta: { tx: 2 },
        component: () =>
            import ('@/views/edit-with-password')
    },
    {
        path: '/message',
        name: 'Message',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/message')
    },
    {
        path: '/notice',
        name: 'Notice',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/notice')
    },
    { path: '*', redirect: '/404' }
]

const _isMobile = function() {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    return flag
}

const router = new VueRouter({ routes: _isMobile() ? routes : pcrouter })

router.afterEach((to) => {
    localStorage.setItem('fullPath', to.fullPath)
})
export default router