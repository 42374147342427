module.exports = {
    pcregister: {
        p1: '¿Ya tienes una cuenta?',
        dl: 'Iniciar sesión',
        zc: 'Registro',
        p4: 'Cuentas',
        p5: 'Por favor ingrese el número de cuenta',
        p6: 'Por favor ingrese la contraseña',
        p7: 'Ingrese la contraseña de confirmación',
        p8: 'Ingrese el código de invitación',
        p9: 'Estoy de acuerdo',
        p10: 'Acuerdo de usuario y política de privacidad',
        p11: 'Correo electrónico',
        p12: 'Por favor ingrese el buzón',
        p13: 'Ingrese el código de verificación del buzón',
        p14: 'Enviar',
        p15: 'Año',
        p16: 'Mes',
        p17: 'Día',
        p18: 'Horas',
        p19: 'Minutos',
        p20: 'Segundos'
    },
    pclogin: {
        l1: '¿No tienes una cuenta?',
        l2: 'Registro',
        l3: 'Iniciar sesión',
        l4: 'Por favor ingrese el nombre de usuario',
        l5: 'Por favor ingrese la contraseña',
        l6: 'Olvidé mi contraseña',
        l7: 'Iniciar sesión'
    },
    pccontract: {
        ss: 'Precio de mercado',
        xj: 'Precio límite',
        gg: 'Apalancamiento'
    },
    pcrapidly: {
        zf: 'Aumento',
        dd: 'Orden',
        bdsj: 'Hora local',
        ky: 'Disponible'
    },
    pchome: {
        global1: 'La plataforma de comercio de activos digitales líder en el mundo',
        global2: 'Ofrece servicios de gestión de activos y transacciones de activos digitales seguros y confiables para millones de usuarios en más de 100 países.',
        global3: 'Seguro y confiable',
        global4: 'Cinco años de experiencia en servicios financieros de activos digitales. Centrarse en arquitecturas distribuidas y sistemas anti-ataques DDoS.',
        global5: 'Diseño ecológico global',
        global6: 'Se han establecido centros de servicios de transacciones localizados en muchos países para crear un ecosistema blockchain que integre múltiples formatos.',
        global7: 'El usuario primero',
        global8: 'Establecer un mecanismo de compensación por adelantado y establecer un fondo de protección al inversor.',
        whever1: 'Transacción de terminal multiplataforma en cualquier momento y en cualquier lugar',
        whever2: 'Cubre iOS, Android y Windows en múltiples plataformas y admite funciones para todos los servicios.',
        register1: 'Registrar uno',
        register3: 'Las cuentas globales comienzan a operar',
        register2: 'Regístrese ahora'
    },
    pcbottom: {
        box1: 'Líder mundial',
        box2: 'Uno de los mejores equipos',
        box3: 'Más',
        box4: 'Sobre nosotros',
        box5: 'Libro blanco',
        box6: 'Servicios',
        box7: 'Descargar',
        box8: 'Preguntas frecuentes',
        box9: 'Servicio al cliente en línea',
        box10: 'Disposiciones',
        box11: 'Acuerdo de usuario',
        box12: 'Política de privacidad',
        box13: 'Declaración legal',
        box14: 'Contacto'
    },
    pcAbout_us: {
        t1: 'Proceso',
        t2: 'Nosotros, el camino de 7 años',
        t3: 'Desde su nacimiento en 2015, hemos estado inmersos en servicios de blockchain, tecnología de tiempo completo y servicios de construcción de canales.',
        t4: 'De 2014 a 2017, experimenté un período ascendente de "estabilidad y aumento". La primera mitad de 2017-2018 fue un período loco de "comprar dinero para hacerse rico". Desde la segunda mitad de 2019 hasta el invierno de hoy, he desarrollado una habilidad real. Innumerables socios exitosos nos han hecho discretos con una reputación de alto perfil en la industria. En 2022, tendremos la nueva plataforma V3.0 y crearemos la nueva sociedad anónima BitGoldEX, que opera en más de 100 países de todo el mundo.',
        t5: '¿A quién estamos buscando?',
        t6: 'Desde su fundación hasta hoy',
        t7: 'He estado buscando proveedores de servicios que tengan dificultades y tengan el mismo gusto, y he resuelto con éxito problemas para docenas de proveedores de servicios durante varios años.',
        t8: 'En la racionalidad de hoy, si desea encontrar un buen ayudante y un socio que pueda trabajar juntos para lograr su propio progreso, tal vez pueda hablar con nosotros.',
        t9: 'Por favor crea: la persona que estás buscando te ha estado buscando.',
        t10: 'Servicios',
        t11: 'Sin vendedor, también hay negocios',
        t12: 'Un amigo le presenta el negocio, lo que demuestra que está bien. Los compañeros le presentan el negocio, lo que demuestra que su reputación es buena. Cuando un cliente le presenta un negocio, demuestra que es confiable.',
        t13: 'Espíritu',
        t14: 'Somos ladrillos, ¿cómo podemos ir?',
        t15: 'Diferentes clientes tienen diferentes requisitos. Diferentes productos tienen diferentes características. Diferentes momentos, diferentes objetivos. El significado de nuestra existencia es adaptarse a las ideas, sistemas y requisitos de diferentes proveedores de servicios y resolver diversos problemas.',
        t16: 'Estamos dispuestos a hacer un ladrillo reflexivo y útil, donde sea necesario, iremos.'
    },
    pcproblem: {
        p1: 'Preguntas frecuentes',
        p2: '¿Por qué cambia la cantidad de activos convertidos?',
        p3: 'El cálculo equivalente en un activo es el valor de la moneda digital actualmente mantenida, convertida en USDT, que varía debido a las fluctuaciones de precios del activo digital. La cantidad de sus activos digitales no ha cambiado.',
        p4: 'Método de procesamiento de contraseña de fondos olvidados para transacciones de plataforma',
        p5: 'Si olvida la contraseña del fondo de transacción de la plataforma, vaya a "Mi configuración-haga clic para establecer la contraseña del fondo" o comuníquese con el servicio al cliente para restablecer',
        p6: '¿A dónde va el dinero después de la compra?',
        p7: 'Una vez completada la orden de compra, los fondos ingresarán a la cuenta de moneda legal en el activo. Si necesita comprar activos para transacciones de contratos o transacciones de futuros, vaya al módulo de transferencia de fondos para transferir.',
        p8: 'Por qué limitar el número de cambios en los apodos',
        p9: 'Por razones de seguridad para su transacción, actualmente restringimos el apodo solo una vez.',
        p10: '¿Qué significa cuando se transfiere? Por qué es necesario transferir',
        p11: 'Para garantizar la independencia de los fondos entre sus cuentas y facilitar su control razonable de los riesgos, por la presente dividimos las cuentas de los principales módulos comerciales. La transferencia se refiere al proceso de conversión de activos entre cuentas comerciales.',
        p12: '¿Cómo calcular el patrimonio de la cuenta del contrato de usuario?',
        p13: 'Valor neto de la cuenta de contrato = margen de posición Posición de beneficio flotante Cantidad disponible en la cuenta corriente',
        p14: '¿Cuál es el valor de stop loss y take profit al abrir una posición? ¿Cómo se debe configurar?',
        p15: 'El límite de pérdidas y ganancias que establece para usted. Cuando se alcanza el monto establecido de stop-profit, el sistema liquidará automáticamente la posición. Se puede utilizar para controlar el riesgo al comprar un contrato. Establezca la mitad de la ganancia: aumente la cantidad x cantidad x apalancamiento, establezca el stop loss. Le recomendamos que lo configure de acuerdo con sus activos reales y controle razonablemente el riesgo.',
        p16: '¿Cómo reducir el riesgo contractual?',
        p17: 'Puede transferir los activos disponibles de la cuenta restante a la cuenta de contrato mediante la transferencia de fondos, o reducir el riesgo mediante la reducción de posiciones',
        p18: '¿Qué significa el número de contratos abiertos?',
        p19: 'El número de posiciones abiertas representa la cantidad de divisas que espera comprar. Por ejemplo: seleccione en la página de apertura del par de operaciones BTC/USDT, haga largo, ingrese el precio como 1000USDT, ingrese la cantidad como 10BTC, es decir: usted espera comprar 10BTC, el precio unitario es 1000USDT.',
        p20: '¿Cómo se calcula la tarifa de manejo en el contrato?',
        p21: 'Tarifa de manejo = precio de apertura * cantidad de apertura * tasa de tarifa de manejo',
        p22: 'Precauciones para la liquidación forzada',
        p23: 'El grado de riesgo es una medida del riesgo de sus activos. Cuando el grado de riesgo es igual al 100%, su posición se considera cerrada, grado de riesgo = (tenencia de margen/capital de la cuenta del contrato) * 100%, para evitar que los usuarios usen la posición, el sistema establece la relación de riesgo de ajuste. Cuando el nivel de riesgo alcanza el valor de riesgo establecido por el sistema, el sistema forzará la liquidación. Por ejemplo, si la tasa de ajuste establecida por el sistema es del 10%, cuando su riesgo es mayor o igual al 90%, el sistema obligará a cerrar todas sus posiciones.',
        p24: '¿Cuáles son las reglas de negociación de contratos?',
        p25: 'Tipo de transacción',
        p26: 'Los tipos de negociación se dividen en posiciones largas (compra) y cortas (venta):',
        p27: 'Comprar largos (alcistas) significa que cree que es probable que el índice actual suba, y desea comprar una cierta cantidad de contratos a un precio que establezca o al precio de mercado actual.',
        p28: 'La venta corta (bajista) es cuando cree que el índice actual puede caer y desea vender una cierta cantidad de contratos nuevos al precio que establezca o al precio de mercado actual.',
        p29: 'Método de pedido',
        p30: 'Pedido de precio limitado: debe especificar el precio y la cantidad del pedido',
        p31: 'Orden de precio de mercado: solo necesita establecer la cantidad del pedido, el precio es el precio de mercado actual',
        p32: 'Posición',
        p33: 'Cuando se completa la instrucción de apertura que envió, se llama posición',
        p34: 'Problema de entrega del contrato',
        p35: 'El contrato de la plataforma es un contrato perpetuo y no hay tiempo de entrega. Siempre que el sistema no cumpla con las condiciones de nivelación o no cierre manualmente su posición, puede mantener una posición permanente. Liquidación del sistema',
        p36: '1: Alcanzar el valor establecido de stop-profit y stop loss, el sistema cierre automáticamente la posición',
        p37: '2: El riesgo es demasiado alto, el sistema se ve obligado a cerrar la posición',
        p38: '¿Cuál es el margen para el comercio de contratos?',
        p39: 'En las transacciones de contrato, los usuarios pueden participar en la compra y venta de contratos de acuerdo con el precio y la cantidad del contrato, y el apalancamiento correspondiente. El usuario tomará el margen cuando abra una posición. Cuanto mayor sea el margen para abrir una posición, mayor será el riesgo de la cuenta.',
        p40: '¿Qué son las órdenes de límite y las órdenes de mercado?',
        p41: 'La orden de límite se refiere al precio al que espera confiar la plataforma para negociar, y la orden de precio de mercado se refiere a confiar directamente a la plataforma para negociar al precio actual. En las reglas de apertura, la orden de mercado tiene prioridad sobre la orden de límite. Si elige una orden de límite, abra una posición razonablemente de acuerdo con el precio de mercado actual de la moneda para evitar pérdidas debido a precios de apertura irrazonables.',
        p42: '¿Qué representa el nivel de riesgo del comercio de contratos?',
        p43: 'El nivel de riesgo es un indicador de riesgo en su cuenta de contrato. El grado de riesgo igual al 100% se considera liquidación. Recomendamos que cuando su riesgo supere el 50%, debe tener cuidado al abrir una posición para evitar pérdidas debido a la liquidación. Puede reducir el riesgo repondiendo los fondos disponibles de los activos del contrato o aligerando su posición.',
        p44: '¿Por qué se necesita un cambio de moneda?',
        p45: 'El propósito del intercambio es permitir que los fondos en diferentes monedas de su activo fluyan sin problemas, y los QCC obtenidos en la cuenta de futuros se pueden convertir libremente a USDT para el comercio. USDT en otras cuentas también es libre de convertir a QCC para el comercio.',
        p46: '¿Por qué la otra parte no recibió la transferencia a tiempo después de la transferencia?',
        p47: 'En primer lugar, asegúrate de que el método de pago que completaste al transferir es exactamente el mismo que se muestra en la página de detalles del pedido. Luego, confirme si su método de pago es un pago inmediato o un pago tardío. Finalmente, comuníquese con su banco e institución de pago para el mantenimiento del sistema u otras razones.',
        p48: '¿Cómo comprar USDT a través de la plataforma?',
        p49: 'Método 1: seleccione la orden que desea comprar a través de la lista de compra y venta de la plataforma.',
        p50: 'Método 2: Haga clic en el botón Publicar para publicar una orden de compra o venta según sus necesidades para una transacción rápida. El sistema coincidirá rápidamente con los usuarios de compra y venta para usted.',
        p51: 'Nota: Si el pedido de compra no se paga después de 15 minutos, el sistema cancelará automáticamente el pedido. Pague a tiempo. El pedido se cancela más de 3 veces el mismo día, y la transacción no se puede volver a operar el mismo día, y la autoridad de transacción se restablecerá al día siguiente.'
    },
    public: {
        zxkf: 'Servicio al cliente en línea',
        sure: 'Confirmación',
        cancel: 'Cancelación',
        RefreshSucceeded: 'Actualizar con éxito',
        noMoreData: 'No más datos',
        pullingText: 'Despliegue para actualizar...',
        ReleaseText: 'Libera para actualizar...',
        loadingText: 'Cargando...',
        noData: 'No hay registros',
        xq: 'Detalles',
        submit: 'Confirmación',
        kefu: 'Servicio al cliente en línea'
    },
    ChangeType: {
        Upper: 'Recarga del sistema',
        Lower: 'Retirada del sistema',
        Win: 'Beneficio',
        Bet: 'Inversión',
        BuyMining: 'Minería',
        CoinWin: 'Ganancias en nuevas monedas',
        SettleMining: 'Retorno del principal de la minería',
        GiveInterest: 'Ingresos de minería',
        BuyCoin: 'SGD no se ha firmado',
        BuyCoinBack: 'Volver a la compra de SGD',
        UsdtToBtc: 'Usdt a Btc',
        UsdtToEth: 'Usdt a Eth',
        BtcToUsdt: 'Btc a Usdt',
        BtcToEth: 'Btc a Eth',
        EthToUsdt: 'Usdt a Eth',
        EthToBtc: 'Eth a Btc',
        CoinFrozen: 'SGD está congelado',
        CoinThaw: 'Nueva moneda descongelada',
        CoinCancel: 'Cancelación de pedidos de monedas',
        CoinBuy: 'Compra de monedas',
        CoinBuyEntrust: 'Encargo de compra de monedas',
        CoinSellEntrust: 'Encargo de venta de monedas',
        CoinSell: 'Venta de monedas',
        ContractBuy: 'Orden de contrato, deduciendo el margen',
        ContractCharge: 'Orden de contrato, deduciendo la tarifa de manejo',
        ContractCancelToBond: 'Margen de reembolso de cancelación de contrato',
        ContractCancelToCharge: 'Cancelación del contrato',
        ContractCloseProfit: 'Ingresos por liquidación de contratos'
    },
    trade: {
        title: 'Moneda',
        Buy: 'Comprar',
        Sell: 'Vender',
        pc: 'Liquidación',
        cb: 'Costo',
        xj1: 'Precio actual',
        yk: 'Ganancias y pérdidas',
        xianjia: 'Precio límite',
        jiage: 'Precio',
        shuliang: 'Cantidad',
        keyong: 'Disponible',
        yonghuchicang: 'Posición del usuario',
        fxlts: 'Tasa de riesgo = margen de posición/capital del cliente multiplicado por el 100%, donde el capital del cliente es igual a los fondos disponibles en la cuenta, margen de negociación inicial, ganancias y pérdidas flotantes',
        fxl: 'Tasa de riesgo',
        dqwt: 'Encargado actualmente',
        all: 'Todo',
        name: 'Nombre',
        shijian: 'Tiempo',
        zyjg: 'Trading al mejor precio actual',
        caozuo: 'Operación',
        xj: 'Precio límite',
        cc: 'Posición',
        leixing: 'Tipo',
        long: 'Hacer más',
        short: 'Corto',
        sj: 'Precio de mercado',
        jiaoyie: 'Volumen de transacciones',
        orderNo: 'Número de pedido',
        weituojia: 'Precio de comisión',
        chengjiaojia: 'Precio de transacción',
        chengjiaoe: 'Volumen de negocios',
        weituo: 'Método de comisión',
        qbwt: 'Todos comisionados',
        lswt: 'Comisión histórica',
        tips: 'Por favor ingrese el precio correcto',
        tips1: 'Por favor ingrese la cantidad',
        Create: 'Encargado',
        Wait: 'Transacción',
        Complete: 'Liquidación',
        Cancel: 'Orden retirada',
        zsj: 'Precio de stop loss',
        zyj: 'Take Profit',
        cczsy: 'Ingresos totales por posiciones',
        ykbl: 'Relación de pérdidas y ganancias',
        bili: 'Proporción',
        jyss: 'Número de transacciones',
        bzj: 'Margen',
        sxf: 'Tarifa de manejo',
        gg: 'Múltiples de apalancamiento',
        yjpc: 'Liquidación con un clic',
        status: 'Estado',
    },
    auth: {
        title: 'Autenticación de identidad',
        title1: 'Autenticación de identidad',
        low: 'Certificación primaria',
        high: 'Certificación avanzada',
        success: 'Certificación exitosa',
        tips: 'Primero haga la certificación primaria',
        tips1: 'Ingrese su nombre real',
        tips2: 'Por favor ingrese el número de identificación',
        tips3: 'Sube la foto frontal de tu tarjeta de identificación',
        tips4: 'Sube la foto en el reverso de tu tarjeta de identificación',
        tips5: 'Sube fotos de mano',
        qrz: 'Ir a la certificación',
        shz: 'En revisión',
        rzsb: 'Autenticación fallida',
        userName: 'Nombre real',
        IDNumber: 'Número de identificación',
        zmz: 'Foto frontal de la tarjeta de identificación',
        bmz: 'Foto de la parte posterior de la tarjeta de identificación',
        scz: 'Foto de mano'
    },
    share: {
        title: 'Mi código de promoción',
        cp: 'Copiar dirección',
        success: 'Copia exitosa'
    },
    property: {
        Contract: 'Moneda',
        Exchange: 'Segundo contrato',
        zzc: 'Equivalente total',
        mhyzh: 'Cuenta de contrato de segundos',
        bbzh: 'Cuenta de moneda',
        available: 'Disponible',
        OnOrders: 'Congelación',
        Estimated: 'Equivalo',
        cw: 'Registros financieros',
        number: 'Cantidad',
        record: 'Registro',
        Transfer: 'Transferir',
        time: 'Tiempo',
        dhsl: 'Cantidad de cambio',
        tips: 'Ingrese la cantidad de canje',
        qbdh: 'Canjear todo',
        dhhl: 'Tipo de cambio',
        ky: 'Disponible',
        kd: 'Disponible',
        sxfbl: 'Ratio de tarifa de manejo',
        sxf: 'Tarifa de manejo'
    },
    statusLang: {
        Review: 'Auditoría',
        Success: 'Éxito',
        Fail: 'Fracaso'
    },
    with: {
        title: 'Retirada',
        history: 'Registro de retiro',
        ldz: 'Dirección de la cadena',
        tbdz: 'Dirección de extracción de moneda',
        tips: 'Ingrese la dirección de la moneda',
        tips1: 'Ingrese la cantidad de monedas',
        tips2: 'Ingrese la contraseña de pago',
        tbsl: 'Número de monedas',
        ky: 'Disponible',
        zjmm: 'Contraseña de pago',
        sxf: 'Tarifa de manejo',
        dz: 'Cantidad estimada de llegada'
    },
    tabBar: {
        home: 'Inicio',
        Quotation: 'Cotizaciones',
        Quotation2: 'Cotizaciones de mercado',
        Trade: 'Moneda',
        Contract: 'Segundo contrato',
        assets: 'Activos',
        qhyy: 'Cambiar de idioma',
        zyjb: 'Mina de bloqueo',
        jsjy: 'Comercio rápido',
        jryw: 'Negocio financiero',
        hyjy: 'Transacción por contrato',
        bbjy: 'Intercambio de monedas'
    },
    home: {
        UserName: 'Cuentas',
        kjmb: 'Compra rápida de monedas',
        score: 'Puntos de crédito',
        zc: 'Soporte BTC, USDT y más',
        scwk: 'Mina de bloqueo',
        sgxb: 'Compra de moneda nueva',
        aqkx: 'Seguro y confiable',
        aqkx1: 'La plataforma de comercio de activos digitales líder en el mundo',
        zfb: 'Lista de aumento',
        dfb: 'Lista de caídas',
        mc: 'Nombre',
        hy: 'Contrato',
        zxj: 'Último precio',
        zdb: 'Aumento y disminución',
        sfyz: 'Autenticación de identidad',
        skfs: 'Método de pago',
        share: 'Mi enlace para compartir',
        kjcb: 'Carga rápida de monedas',
        kjtb: 'Moneda rápida',
        qbdz: 'Dirección de billetera',
        bps: 'Libro blanco',
        xgmm: 'Modificar la contraseña',
        xgzfmm: 'Modificar la contraseña de pago',
        gywm: 'Sobre nosotros',
        tcdl: 'Salir'
    },
    quotation: {
        title: 'Cotizaciones',
        mc: 'Cantidad',
        zxj: 'Último precio',
        zdb: 'Aumento y disminución',
        liang: 'Cantidad'
    },
    contract: {
        kcslfw: 'Rango de número de posiciones abiertas:',
        gao: 'Alto',
        di: 'Bajo',
        liang: 'Cantidad 24H',
        placeholder: 'Por favor ingrese la cantidad',
        SuccessfulPurchase: 'Compra exitosa',
        smallMin: 'Minutos',
        hour: 'Horas',
        dayMin: 'Día',
        Trend: 'Tiempo compartido',
        ccjl: 'Lista de posiciones',
        ddjl: 'Registro de pedidos',
        sl: 'Cantidad',
        gmj: 'Precio de compra',
        dqj: 'Precio actual',
        yjyk: 'Pérdidas y ganancias estimadas',
        djs: 'Cuenta atrás',
        gbjg: 'Precio de transacción',
        sc: 'Tarifa de manejo',
        pal: 'Ganancias y pérdidas',
        mhy: 'Segundo contrato',
        sj: 'Tiempo',
        yll: 'Tasa de beneficio',
        mz: 'Comprar',
        md: 'Comprar abajo',
        kcsl: 'Número de posiciones abiertas',
        kcsj: 'Tiempo de apertura',
        zhye: 'Saldo de cuenta',
        qc: 'Cancelación'
    },
    editPassword: {
        title: 'Modificar la contraseña',
        jmm: 'Contraseña antigua',
        xmm: 'Nueva contraseña',
        qrmm: 'Confirmar la contraseña',
        placeholder: 'Contraseña antigua',
        placeholder1: 'Establecer una nueva contraseña',
        placeholder2: 'Vuelva a ingresar la nueva contraseña',
        qrxf: 'Confirmación'
    },
    withPass: {
        title: 'Contraseña de retiro',
        jmm: 'Contraseña antigua',
        xmm: 'Nueva contraseña',
        qrmm: 'Confirmar la contraseña',
        placeholder: 'Contraseña antigua',
        placeholder1: 'Establecer una nueva contraseña',
        placeholder2: 'Vuelva a ingresar la nueva contraseña',
        qrxf: 'Confirmación',
        tips: 'Ingrese la contraseña anterior de al menos 6 dígitos',
        tips1: 'Introduzca una nueva contraseña de al menos 6 dígitos',
        tips2: 'Ingrese la contraseña de confirmación correcta',
        tips3: 'La nueva contraseña ingresada dos veces es inconsistente',
        tips4: 'Establecer la contraseña correctamente',
        confirm: 'Confirmación'
    },
    newBe: {
        title: 'Compra de moneda nueva',
        title1: 'Mi suscripción',
        scqx: 'Período de bloqueo',
        day: 'Día',
        sy: 'El resto',
        sdsg: 'Registro',
        sysl: 'Cantidad restante',
        gmsl: 'Cantidad de compra',
        dj: 'Precio unitario',
        tips: 'Por favor ingrese la cantidad de compra',
        gmzj: 'Precio total de compra',
        qbye: 'Saldo de billetera',
        fmzj: 'Precio total de compra',
        gm: 'Compra',
        sgsj: 'Tiempo de compra',
        Currency: 'Moneda',
        Request: 'Número de solicitudes',
        Passing: 'Por cantidad',
        MarketTime: 'Tiempo de comercialización',
        sqz: 'En aplicación'
    },
    register: {
        title: 'Registro',
        lang: 'Idioma',
        send: 'Enviar',
        Resend: 'Reenviar',
        Account: 'Cuentas',
        Password: 'Contraseña',
        ConfirmPassword: 'Confirmar la contraseña',
        InvitationCode: 'Código de invitación',
        btn: 'Registro',
        amount: 'Cuenta',
        mailbox: 'Correo electrónico',
        haveAccount: '¿Ya tienes una cuenta?',
        Login: 'Iniciar sesión',
        placeholder: 'Por favor ingrese el buzón',
        placeholder0: 'Por favor ingrese el número de cuenta',
        placeholder1: 'Por favor ingrese la contraseña',
        placeholder2: 'Ingrese la contraseña de confirmación',
        placeholder3: 'Ingrese el código de invitación',
        placeholder4: 'Ingrese el código de verificación del buzón',
        placeholder5: 'Ingrese el buzón correcto',
        success: 'Registro exitoso',
        message: 'Contraseña de al menos 6 dígitos',
        message1: 'Confirme la contraseña de al menos 6 dígitos',
        message2: 'Dos veces la contraseña no coincide',
        message3: 'Lea y marque el acuerdo',
        agree: 'Estoy de acuerdo',
        xy: 'Acuerdo de usuario y política de privacidad',
        qr: 'Confirmación'
    },
    lockming: {
        title: 'Lista de bloqueo',
        wkb: 'Tesoro de minería',
        wkgjd: 'La minería es más sencilla',
        qsljl: 'Recompensas fáciles',
        yjzsr: 'Ingresos totales estimados',
        ck: 'Año de referencia en los últimos 3 días',
        tips: 'Al menos',
        rlx: 'Interés diario',
        day: 'Día',
        scqx: 'Período de bloqueo',
        buy: 'Compra',
        zysl: 'Cantidad de prenda',
        qbye: 'Saldo de billetera',
        finished: 'Completar',
        doing: 'En progreso...',
        sl: 'Cantidad',
        shouyilv: 'Rendimiento',
        ks: 'Hora de inicio',
        js: 'Tiempo de finalización'
    },
    login: {
        title: 'Iniciar sesión',
        lang: 'Idioma',
        Account: 'Cuentas',
        Password: 'Contraseña',
        btn: 'Iniciar sesión',
        placeholder1: 'Por favor ingrese la contraseña',
        placeholder: 'Por favor ingrese el número de cuenta',
        fail: 'Por favor complete el formulario completo',
        ForgotPassword: 'Olvidé mi contraseña',
        NoAccount: '¿Aún no tienes una cuenta?',
        Registration: 'Registro',
        server: 'Servicio al cliente en línea',
        jzmm: 'Recordar la contraseña',
        success: 'Iniciar sesión correctamente',
        Home: 'Inicio'
    },
    moneyAddr: {
        title: 'Dirección de billetera',
        Currency: 'Moneda',
        Address: 'Dirección',
        placeholder: 'Por favor elija',
        DepositAddress: 'Dirección de recarga',
        WalletAddress: 'Dirección de billetera',
        Date: 'Tiempo',
        add: 'Agregar dirección de billetera',
        ChainAddress: 'Dirección de la cadena',
        qrcode: 'Código QR de billetera',
        submit: 'Confirmación',
        tips: 'Por favor seleccione el tipo de billetera',
        tips1: 'Seleccione el tipo de dirección de cadena',
        tips2: 'Ingrese la dirección de la billetera',
        tips3: 'Sube el código QR de la billetera',
        success: 'Éxito'
    },
    recharge: {
        title: 'Recarga',
        title1: 'Detalles de recarga',
        title2: 'Detalles del retiro de efectivo',
        ls: 'Detalles de recarga',
        td: 'Seleccione el siguiente canal de recarga',
        bc: 'Guardar el código QR',
        address: 'Dirección de recarga',
        copy: 'Copiar dirección',
        num: 'Cantidad de recarga',
        Upload: 'Sube capturas de pantalla de detalles de pago',
        Submit: 'Confirmación',
        tip: 'Por favor ingrese la cantidad',
        tip1: 'Sube la captura de pantalla',
        copySuc: 'Copia exitosa'
    },
    newChange: {
        num: 'Por favor ingrese la cantidad',
        psd: 'Por favor ingrese la contraseña',
        hq: 'Cotizaciones',
        bb: 'Centro de ayuda',
        sec: 'Autenticación de nombre real',
        ass: 'Activos',
        pool: 'Gestión financiera',
        newc: 'Sobre nosotros',
        rechange: 'Recarga',
        with: 'Servicio al cliente',
        help: 'Centro de ayuda',
        a1: 'Finanzas',
        a2: 'Fondos bajo custodia (USDT)',
        a3: 'Beneficio hoy',
        a4: 'Ganancias acumuladas',
        a5: 'En progreso',
        a6: 'Orden',
        a7: 'Reglas',
        a8: 'Recompensas diarias',
        a9: 'Límite único',
        a10: 'Ciclo',
        a11: 'Pecently (ingresos diarios)',
        a12: 'Límite único',
        a13: 'Cantidad de alojamiento:',
        a14: 'Suscripción',
        a15: 'Cálculo de beneficios',
        a16: 'La mudanza de ladrillos es a través del alojamiento de USDT a la plataforma, y la plataforma tiene un equipo profesional para el arbitraje de mudanzas de ladrillos...',
        a17: 'Un miembro depositó 10000U en la plataforma y eligió un producto de gestión patrimonial con un período de 5 días y un rendimiento diario del 0,3% al 0,5%. Máximo diario: 10000U x 0,5% = 50 U n, es decir, se pueden obtener ingresos de 150U-250U después de 5 días. Los ingresos se distribuyen diariamente y los ingresos emitidos se pueden acceder en cualquier momento. El principal depositado ha caducado, después de lo cual será...',
        a18: 'Sobre los daños liquidados',
        a19: 'Si desea transferir el capital no vencido, se incurrirá en daños y perjuicios. Daños liquidados = índice de liquidación por incumplimiento * días restantes * número de inversiones. Ejemplo N: el índice de liquidación predeterminado del producto es 0.4%, los 3 días restantes expiran y el monto de la inversión es 1000, luego los daños liquidados = 0.4% * 3...',
        a20: 'Quiero unirme'
    },
    msgbank: {
        msgTitle: 'mensaje',
        noticeTitle: 'anuncio',
        msgContent: 'usted tiene mensajes no leídos',
        msgBtn: 've a ver',
        bankCard: 'tarjeta bancaria',
        inputTips: 'por favor, introduzca la información completa',
        submitSuccess: 'enviar con éxito',
        CardUserName: 'nombre de la persona que abrió la cuenta',
        BankName: 'banco de apertura de cuentas',
        OpenBank: 'abrir puntos de venta',
        CardNo: 'número de tarjeta bancaria',
        noBindBankmsg: 'todavía no tiene una tarjeta bancaria vinculada',
        noBindBankBtn: 'unbind',
        modify: 'Modificación',
    }
}