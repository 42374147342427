/* // 获取屏幕宽度(viewport)
let htmlWidth =
  document.documentElement.clientWidth || document.body.clientWidth

// 获取html的dom
let htmlDom = document.getElementsByTagName('html')[0]

// 设置html的fontsize
htmlDom.style.fontSize = htmlWidth / 3.75 + 'px'

window.addEventListener('resize', e => {
  console.log(e)
  let htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth
  htmlDom.style.fontSize = htmlWidth / 3.75 + 'px'
})
 */

/*
 * @Description:
 * @Author: 爱酱丶
 * @Date: 2022-11-03 17:07:20
 * @LastEditTime: 2022-11-08 12:34:33
 * @LastEditors: 爱酱丶
 */
// 获取屏幕宽度(viewport)
let htmlWidth =
  document.documentElement.clientWidth || document.body.clientWidth

// 获取html的dom
let htmlDom = document.getElementsByTagName('html')[0]

// 设置html的fontsize
if (
  document.documentElement.clientWidth >= 768 ||
  document.body.clientWidth >= 768
) {
  htmlDom.style.fontSize = 100 + 'px'
} else {
  htmlDom.style.fontSize = htmlWidth / 3.75 + 'px'
}
window.addEventListener('resize', () => {
  let htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth
  if (
    document.documentElement.clientWidth >= 768 ||
    document.body.clientWidth >= 768
  ) {
    return (htmlDom.style.fontSize = 100 + 'px')
  }

  htmlDom.style.fontSize = htmlWidth / 3.75 + 'px'
})
