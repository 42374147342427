import Vue from 'vue'
import Vuex from 'vuex'
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { showName } from '@/utils/validate'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('language') || 'en',
    basicConfig: {},
    connection: null,
    productList: []
  },
  mutations: {
    SET_LANG: (state, lang, Flag = true) => {
      state.lang = lang
      if (Token.get() && Flag) {
        userApi.setLanguage({
          LanguageType: showName(lang)
        })
      }
    },
    SET_BASIC: (state, basic) => {
      state.basicConfig = basic
    },
    SET_CONNECTION: (state, connection) => {
      state.connection = connection
    },
    SET_PRODUCTLIST: (state, productList) => {
      state.productList = productList
    }
  },
  getters: {
    lang: (state) => state.lang,
    basicConfig: (state) => state.basicConfig,
    connection: (state) => state.connection,
    productList: (state) => state.productList
  },
  actions: {},
  modules: {}
})
